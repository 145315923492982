import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class Proof {
  public loading: boolean = false;
  public visibleDisplayQrCode: boolean = false;
  public visibleWebArQrCode: boolean = false;
  public visibleMarkerlessQrCode: boolean = false;
  public visibleSendEmail: boolean = false;
  public visibleCloneExperience: boolean = false;
  public saveDataQrCode: boolean = false;
  public experienceChangedName: boolean = false;
  public experienceInputNameBeforeChanged: string = '';
  public accountListClone: any = null;
  public currentExperience: any = null;
  public visibleQRData: string = '';
  public linkShare: string = '';
  public imageQrCode: string = '';
  public currentStateProof: string = '';
  public mobileAppLinks: any = { ios: null, android: null };
  public data: any = { state: '', title: '', id: null, app: null, value: null };
  public sendEmailForm: UntypedFormGroup = null;
  public sendCloneExperienceForm: UntypedFormGroup = null;
  public experiencesSubscription: Subscription = null;
  public isMarkerless: boolean = false;
  public bareUrl: string = '';
}
